


















import { Vue, Prop, Component } from 'vue-property-decorator';
import { Collapse, CollapseItem } from 'element-ui';
import Tab from '@/model/Tab';
@Component({ components: { Collapse, CollapseItem } })
export default class InsAccordion extends Vue {
    @Prop({ default: new Tab('none') }) private contents!:Tab;
    @Prop({ default: false }) private slotF!:boolean;
    @Prop({ default: '' }) private slotTitle!:string;
    private activeNames:string[] = ['1'];
}
