





















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsRegisterSuccess extends Vue {
  handleCommand () {
    this.$router.push('/account/login');
  }
};

