




















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import inButton from '@/components/base/mobile/InsButton.vue';
@Component({ components: { inButton } })
export default class ProductUpOrDown extends Vue {
  @Prop() private readonly catId!:number;
  @Prop() private readonly sku!:string;
  up () {
    this.$Api.product.GetProductUpOrDown(this.sku, this.catId, true).then((result) => {
      this.$router.push({ name: 'ProductsDetail', params: { id: result } });
    }).catch(error => {
      console.log(error);
      this.$message('沒有更多了');
    });
  }
  down () {
    this.$Api.product.GetProductUpOrDown(this.sku, this.catId, false).then((result) => {
      this.$router.push({ name: 'ProductsDetail', params: { id: result } });
    }).catch(error => {
      console.log(error);
      this.$message('沒有更多了');
    });
  }
}
