












































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsAddressPopUp extends Vue {
  currentCode: any = '';
  selectAddress: any = '';
  selectPickupAddress: any = '';
  selectExpressOne: number = 0;
  expressList: any[] = [
    {
      CountryCode: null,
      Discount: 50,
      DiscountPrice: 0,
      ExpressCompanyId: '1046',
      ExpressCompanyName: '郵費（香港）',
      ExpressPointId: '0',
      ExpressPointList: null,
      Id: '1046',
      IsExpressPoint: false,
      IsSelfDefineDeliveryDate: true,
      Price: 50,
      ServiceType: null
    }
  ];
  ExpressPointList: any[] = [
    {
      Address: 'rehjujknbgvchm',
      City: 'hk',
      CityName: 'hk',
      CompanyName: null,
      Country: {
        Code: 'HKG',
        Code2: '  ',
        Code3: '   ',
        CountryItem: null,
        Id: 786,
        Language: null,
        Name: '中國香港',
        Name_c: null,
        Name_e: null,
        Name_j: null,
        Name_s: null,
        Provice: null,
        Selected: false,
        date_changed: null,
        is_Del: null
      },
      CountryCode: null,
      CountryId: '786',
      CountryName: null,
      Default: true,
      DeliveryId: 70367,
      Email: null,
      FirstName: 'cs',
      FullName: 'cs cs',
      Gender: null,
      IsUsable: true,
      LastName: 'cs',
      MemberId: 'e52ad94fecdc4d78876ec0e2ac4d293f',
      Mobile: 'aeraweraw',
      NickName: null,
      Phone: 'aeaweaw',
      PostalCode: 'aaeaw',
      Province: '0',
      ProvinceName: null,
      Remark: null
    }
  ];
  countryList: any = [
    {
      Name: '中國香港'
    }
  ];
  provinceList: any = [
    {
      Name: '中國香港'
    }
  ];
  created () {
    this.$HiddenLayer();
  }
}
