
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue'),
    cmsMenuTree: () => import('@/components/business/pc/cms/InsCmsMenuTree.vue')
  }
})
export default class insNews extends Vue {
}
