





import { Vue, Prop, Component } from 'vue-property-decorator';
import Currency from '@/model/currency';
@Component
export default class InsPrices extends Vue {
    @Prop() primePrices!:string;
    @Prop() currentPrices!:string;
    @Prop() size!:string;
    @Prop() heightLine!:boolean;
    @Prop() styla!:string;
    @Prop() currency!: Currency;
    private Sizes = ['small', 'middle', 'large', 'huge', 'sb'];
    get HL ():string {
      return this.heightLine ? ' ' + this.Sizes[this.Sizes.indexOf(this.size) + 1] : '';
    }
    created () {
      if (this.Sizes.indexOf(this.size) === -1) {
        throw new Error('inPirces error:missing type name:' + this.size + '.And we only contain ' + this.Sizes.slice(0, this.Sizes.length - 1));
      }
    }
}
