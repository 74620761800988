






















import YouWouldLike from '@/model/youWouldLike';
import inButton from '@/components/base/pc/InsButton.vue';
import inProductWindow from '@/components/business/pc/product/InsProductWindow.vue';
import Currency from '@/model/currency';
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component({
  components: {
    inButton, inProductWindow
  }
})
export default class InsPyramidSelling extends Vue {
  // data
  private SwiperOption = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };
  private InnerItems: YouWouldLike[] = [];
  private ShowItems: YouWouldLike[][] = [];
  //   props
  @Prop() private readonly styla!: string;
  @Prop() private readonly imgStyla!: string;
  @Prop() private readonly title!: string;
  @Prop() private readonly pageNum!: number;
  @Prop() private readonly items!: YouWouldLike[];
  @Prop() private readonly url!: string;
  //   method
  click (e: MouseEvent) {
    let target = e.target as HTMLElement;
    if (target.nodeName === 'IMG') {
      console.log('use the ' + target.dataset.key + ' to do something');
    }
  }
  buttonClick (item: YouWouldLike) {
    console.log(item);
  }
  created () {
    if (!this.url) {
      throw new Error('inPyramidSelling component:param url is aquired!');
    } else { console.log('we used ' + this.url + ' to get source'); };
    if (!this.items) {
      throw new Error('inPyramidSelling component:param items(YouWouldLike Array) is aquired!');
    }
    this.InnerItems = this.items.slice(0);
    while (this.InnerItems.length > 0) {
      this.ShowItems.push(this.InnerItems.splice(0, this.pageNum));
    }
    while (
      this.ShowItems.length > 0 &&
      this.ShowItems[this.ShowItems.length - 1].length < this.pageNum
    ) {
      this.ShowItems[this.ShowItems.length - 1].push(
        new YouWouldLike('-1', '', '', '', '', '', new Currency(), true)
      );
    }
  }
}
