












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    NewsNav: () => import('@/components/business/mobile/news/InsNewsNav.vue'),
    InsBanner: () => import('@/components/base/mobile/InsBanner.vue')
  }
})
export default class InsNews extends Vue {
  NewsNav: string = 'NewsNav';
}
