



















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { Form } from 'element-ui';
@Component
export default class Recommend extends Vue {
      @Prop() private Skus!:string;
      private rules = {
        ToEmail: [
          { required: true, message: '', trigger: 'blur' }
        ],
        Remark: [
          { required: true, message: '', trigger: 'blur' }
        ]
      }
      updated () {
        this.rules.ToEmail[0].message = this.$t('Login.EnterEmail') as string;
        this.rules.Remark[0].message = this.$t('Login.EnterEmail') as string;
      }
      private dialogFormVisible:boolean = false
      private form = {
        ToEmail: '',
        Remark: ''
      }
      private formLabelWidth:string = '120px'
      cancel () {
        this.dialogFormVisible = false;
      }
      confirm (ToEmail, Remark) {
        this.$Api.member.getProfile2().then((result) => {
          // this.$Login(() => {
          // });
          return result.MemberId;
        }).then((result) => {
          this.$Api.product.SaveMemberRecommendProduct({
            MemberId: result,
            Skus: this.Skus + ',',
            ToEmail: ToEmail,
            Remark: Remark,
            IType: 1
          }).then(result => {
            this.$message(result);
          });
        }).catch((error) => {
          console.error(error);
          this.$Login(this.confirm, ToEmail, Remark);
        });
        this.dialogFormVisible = false;
      }
      openDialog () {
        this.dialogFormVisible = true;
      }
      beforeClose () {
        (this.$refs['form'] as Form).resetFields();
      }
}
