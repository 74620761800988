






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ShopCartItem from '@/model/shopCartItem';
@Component
export default class InsCommodity extends Vue {
  itemsAmount: number = 0;
  currentCode: any = '';
  private items:ShopCartItem[] = [new ShopCartItem()];
  created () {
    this.$store.state.shopCart.then((result) => {
      this.items = result.ShopCart.Items;
    });
  }
}
