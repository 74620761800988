























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import ProductAttr from '@/model/ProductAttr';
@Component
export default class InsSelect2 extends Vue {
  // data
  private Value: string = '';
  private RealValue: string = '';
  private None: boolean = false;
  private Content: string[] = [];
  private DropdownShow: boolean = false;
  private Placeholder = '请选择';
  //   props
  @Prop() private readonly label!: string;
  @Prop() private readonly styla!: string;
  @Prop() private readonly items!: any[];
  @Prop() private readonly v!: string;
  //   method
  choice (e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.nodeName !== 'LI') {
      return;
    }
    this.Value = this.items[target.dataset.key as string][this.label];
    if (this.v) {
      this.RealValue = this.items[target.dataset.key as string][this.v];
    } else this.RealValue = this.items[target.dataset.key as string];
    this.DropdownShow = false;
    this.$emit('input', this.RealValue);
  }
  leftDropdown (e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.nodeName === 'DIV') {
      this.DropdownShow = false;
    }
  }
  //   created
  created () {
    if (this.items && this.items.length > 0) {
      this.Value = this.items[0][this.label];
      if (this.v) {
        this.RealValue = this.items[0][this.v];
      } else this.RealValue = this.items[0];
      this.$emit('input', this.RealValue);
    }
  }
  @Watch('items', { deep: true })
  onItemsChange () {
    if (this.items && this.items !== null && this.items.length > 0) {
      this.Value = this.items[0][this.label];
      if (this.v) {
        this.RealValue = this.items[0][this.v];
      } else this.RealValue = this.items[0];
      this.$emit('input', this.RealValue);
    }
  }
}
