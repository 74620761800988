








import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import Payment from '@/components/business/mobile/account/InsPayment.vue';
import Shoppingcart from '@/views/mobile/InsShoppingcart.vue';
import Fare from '@/components/business/mobile/account/InsFare2.vue';
import Concessions from '@/components/business/mobile/account/InsConcessions.vue';
@Component({ components: { Payment, Shoppingcart, Fare, Concessions } })
export default class InsOrderPreview extends Vue {

}
