






import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import Product from '@/model/youWouldLike';
import inProductWindow from '@/components/business/pc/product/InsProductWindow.vue';
@Component({ components: { inProductWindow } })
export default class InsProductList extends Vue {
    @Prop() private readonly column!: number;
    @Prop() private readonly allItems!: Product[];
}
