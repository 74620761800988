











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue')
  }
})
export default class InsOrderComplete extends Vue {
  newarry: any[] = [];
  OrderId: any = '';
  created () {
    let that = this;
    that.OrderId = that.$route.query.id;
    that.newarry = that.OrderList.filter(function (item) {
      return item.Id === parseInt(that.OrderId);
    });
  }
  OrderList: any[] = [
    {
      Id: 1,
      ItemsAmount: 113,
      DeliveryCharge: 40,
      TotalAmount: 153,
      TotalQty: 3,
      CreateTime: '2019-05-24 17:45:20',
      InvoiceNO: 'E201909001',
      StatusName: '收到订单',
      OrderItems: [
        {
          Qty: 1,
          Product: [
            {
              Name: '雪耳木瓜炖鸡脚汤1',
              Code: 'SOUPER2',
              SalePrice: 59,
              Img:
                'http://pm.dev.in-store.hk:84/ClientResources/3edb5e1b-b7e3-4ef8-b1e7-741f089bef37/product/15/15_m.jpg?flag=63699074854943'
            }
          ]
        },
        {
          Qty: 2,
          Product: [
            {
              Name: '雪耳木瓜炖鸡脚汤2',
              Code: 'SOUPER2',
              SalePrice: 54,
              Img:
                'http://pm.dev.in-store.hk:84/ClientResources/3edb5e1b-b7e3-4ef8-b1e7-741f089bef37/product/15/15_m.jpg?flag=63699074854943'
            }
          ]
        }
      ]
    },
    {
      Id: 2,
      ItemsAmount: 131,
      DeliveryCharge: 40,
      TotalAmount: 171,
      TotalQty: 2,
      CreateTime: '2019-05-26 17:45:20',
      InvoiceNO: 'E201909002',
      StatusName: '收到订单',
      OrderItems: [
        {
          Qty: 1,
          Product: [
            {
              Name: '雪耳木瓜炖鸡脚汤3',
              Code: 'SOUPER2',
              SalePrice: 65,
              Img:
                'http://pm.dev.in-store.hk:84/ClientResources/3edb5e1b-b7e3-4ef8-b1e7-741f089bef37/product/15/15_m.jpg?flag=63699074854943'
            }
          ]
        },
        {
          Qty: 1,
          Product: [
            {
              Name: '雪耳木瓜炖鸡脚4',
              Code: 'SOUPER2',
              SalePrice: 66,
              Img:
                'http://pm.dev.in-store.hk:84/ClientResources/3edb5e1b-b7e3-4ef8-b1e7-741f089bef37/product/15/15_m.jpg?flag=63699074854943'
            }
          ]
        }
      ]
    },
    {
      Id: 3,
      ItemsAmount: 130,
      DeliveryCharge: 40,
      TotalAmount: 170,
      TotalQty: 2,
      CreateTime: '2019-05-24 17:45:30',
      InvoiceNO: 'E201909003',
      StatusName: '收到订单',
      OrderItems: [
        {
          Qty: 1,
          Product: [
            {
              Name: '雪耳木瓜炖鸡脚汤5',
              Code: 'SOUPER2',
              SalePrice: 60,
              Img:
                'http://pm.dev.in-store.hk:84/ClientResources/3edb5e1b-b7e3-4ef8-b1e7-741f089bef37/product/15/15_m.jpg?flag=63699074854943'
            }
          ]
        },
        {
          Qty: 1,
          Product: [
            {
              Name: '雪耳木瓜炖鸡脚汤6',
              Code: 'SOUPER2',
              SalePrice: 70,
              Img:
                'http://pm.dev.in-store.hk:84/ClientResources/3edb5e1b-b7e3-4ef8-b1e7-741f089bef37/product/15/15_m.jpg?flag=63699074854943'
            }
          ]
        }
      ]
    }
  ];
}
