























































































































































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { Form } from 'element-ui';
import Button from '@/components/base/mobile/InsButton.vue';
import Express from '@/model/express';
import Address from '@/model/address';
import { Country } from '@/model/country';
import { Province } from '@/model/province';
import PickupAddress from '@/model/pickupAddress';
import Dialog from '@/components/base/mobile/InsDialog.vue';
@Component({ components: { Dialog, Button } })
export default class InsFare extends Vue {
    private expressList:Express[] = [new Express()];
    private addressList:Address[] = [];
    private PickupAddressList:PickupAddress[] =[];
    private selectAddress:Address = new Address();
    private pickupAddress:PickupAddress = new PickupAddress();
    private TotalWeight:number = 0;
    private ItemsAmount:number = 0;
    private express = new Express();
    private dialogVisible = false;
    private modal = '';
    private editAddress:Address = new Address();
    private countryList:Country[] = [];
    private provinceList:Province[] = [];
    private deliveryType:string = 'D';
    private PickupDate:string = new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() ;
    private PickupTime:number = 1;
    private showPicker:boolean = false;
    private options = [{
      value: 1,
      label: '早上'
    }, {
      value: 2,
      label: '下午'
    }];
    @Prop() private lockFare!: boolean;
    @Prop() private cleanUpdate:boolean | undefined;
    mounted () {
    }
    created () {
      this.deliveryType = this.$store.state.DeliveryType ? this.$store.state.DeliveryType : 'D';
      if (!this.cleanUpdate) {
        Promise.all([this.$store.state.address.then((result) => {
          this.addressList = result.Address;
          result.Address.forEach((element) => {
            if (element.Default) this.selectAddress = element;
          });
        }), this.$store.state.shopCart.then((result) => {
          this.TotalWeight = result.ShopCart.TotalWeight;
          this.ItemsAmount = result.ShopCart.ItemsAmount;
        })]).then((params) => {
          this.$Api.checkout.getExpressWithDiscount({ DeliveryAddrId: this.selectAddress.DeliveryId,
            TotalWeight: this.TotalWeight,
            ItemAmount: this.ItemsAmount }).then(result => {
            this.expressList = result.Express;
            if (result.Express.length && result.Express.length > 0) { this.$store.dispatch('setExpress', result.Express[0]); this.express = this.$store.state.express; }
          });
        });
        this.$Api.address.getPickupAddress().then((result) => {
          this.PickupAddressList = result.PickupAddress;
        });
      } else {
        this.selectAddress = this.$store.state.selectAddress;
      }
    }
    get validateAddress () {
      return {
        FirstName: [
          {
            required: true,
            message: this.$t('MemberInfo.EnterUserName'),
            trigger: 'blur'
          }
        ],
        LastName: [
          {
            required: true,
            message: this.$t('MemberInfo.EnterUserLastName'),
            trigger: 'blur'
          }
        ],
        Phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t('MemberInfo.EnterUserPhone') as string));
              } else if (Number.isNaN(Number(value))) {
                callback(new Error(this.$t('MemberInfo.EnterNumber') as string));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        Mobile: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t('MemberInfo.EnterUserPhone') as string));
              } else if (Number.isNaN(Number(value))) {
                callback(new Error(this.$t('MemberInfo.EnterNumber') as string));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        Country: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.Id === '') {
                callback(new Error(this.$t('Address.Country') as string));
              } else {
                callback();
              }
            },
            // required: true,
            // message: this.$t('Address.Country'),
            trigger: ['blur']
          }
        ],
        Provinceo: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value || value.Id === '' || value.Id === 0) {
                callback(new Error(this.$t('Address.Province') as string));
              } else {
                callback();
              }
            },
            // required: true,
            // message: this.$t('Address.Province'),
            trigger: ['blur']
          }
        ],
        Address: [
          {
            required: true,
            message: this.$t('Address.Address'),
            trigger: 'blur'
          }
        ]
      };
    }
    openDialog () {
      this.$Api.checkout.getCountry().then(result => {
        this.countryList = result.Country;
      });
    }
    save (formName) {
      let form = {
        Province: this.editAddress.Provinceo.Id,
        CountryId: this.editAddress.Country.Id,
        ProvinceName: this.editAddress.Provinceo.Name,
        DeliveryId: this.editAddress['DeliveryId'],
        Default: this.editAddress.Default,
        IsUsable: true,
        FirstName: this.editAddress.FirstName,
        LastName: this.editAddress.LastName,
        Phone: this.editAddress.Phone,
        Mobile: this.editAddress.Mobile,
        PostalCode: this.editAddress.PostalCode,
        Address: this.editAddress.Address
      };
      (this.$refs[formName] as Form).validate((valid) => {
        if (valid) {
          this.$Api.address.saveAddress(form).then((result) => {
          }).then(() => {
            this.$Api.checkout.getAddress2().then((result) => {
              this.addressList = result.Address;
              this.dialogVisible = false;
              this.editAddress = new Address();
              this.$message(this.$t('Message.SucceedInOperating') as string);
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
    editAddr (index) {
      this.deliveryType = 'D';
      Object.keys(this.editAddress).forEach((element) => {
        this.editAddress[element] = this.addressList[index][element];
      });
      // this.province = this.addressList[index].Provinceo;
      this.editAddress['DeliveryId'] = this.addressList[index].DeliveryId;
    }
    removeAddr (index) {
      let address = this.addressList.splice(index, 1);
      if (address.length) { this.$Api.address.removeAddress(address[0].DeliveryId).then(() => { this.$message(this.$t('Message.SucceedInOperating') as string); }); }
    }
    selectAddr (index) {
      this.$store.dispatch('setDeliveryType', 'D');
      this.deliveryType = 'D';
      this.selectAddress = this.addressList[index];
      this.dialogVisible = false;
      this.$message(this.$t('Message.SucceedInOperating') as string);
    }
    selectPickupAddr (index) {
      console.log(this.PickupDate);
      this.deliveryType = 'P';
      this.pickupAddress = this.PickupAddressList[index];
      this.pickupAddress.PickupDate = this.PickupDate.replace(/-/g, '/');
      this.pickupAddress.PickupTime = this.PickupTime;
      this.$store.dispatch('setExpress', new Express());
      this.$store.dispatch('setDeliveryType', 'P');
      this.$store.dispatch('setPickupAddress', this.pickupAddress);
      this.dialogVisible = false;
    }
    @Watch('editAddress.Country')
    onCountryChange (n, o) {
      if (String(n.Id) === '') return;
      this.$Api.checkout.getProvince(String(n.Id)).then(result => {
        this.provinceList = result.Province;
      });
    }
    @Watch('express', { deep: true })
    onValueChange (o, n) {
      this.$store.dispatch('setExpress', this.express);
    }
    @Watch('selectAddress')
    onSelectAddressChange (n, o) {
      if (!this.cleanUpdate) {
        this.$store.dispatch('setSelectAddress', this.selectAddress);
        if (o.DeliveryId === 0) return;
        this.$store.dispatch('setSelectAddress', this.selectAddress);
        this.$Api.checkout.getExpressWithDiscount({ DeliveryAddrId: this.selectAddress.DeliveryId,
          TotalWeight: this.TotalWeight,
          ItemAmount: this.ItemsAmount }).then(result => {
          this.expressList = result.Express;
          if (result.Express.length && result.Express.length > 0) { this.$store.dispatch('setExpress', result.Express[0]); this.express = this.$store.state.express; }
          this.express = this.$store.state.express;
        });
      }
    }
}
